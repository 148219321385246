/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React from 'react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { BaseApi } from 'common/api/common/base-api';

// import { setEmailCampaignDraft } from "store/campaign";
import { useAppDispatch } from 'store';

import { SeoTrafficListRowObject } from 'common/dto/types/views/campaigns';
import { setAndGetQueryParams, useDebounce } from 'common/utils/utility';

import { MdAdd } from 'react-icons/md';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';
import { setCampaignSelectedCSVData } from 'store/campaign';
import ArrowedTooltip from 'components/ArrowedTooltip';
import InfiniteScrollComponent from 'components/infinityScroll';
import TableLoader from 'components/Loaders/TableViewLoader';
import CheckBox from 'common/SparkleCommon/MultiSelectCheckBox.tsx';
import { useNavigate } from 'react-router-dom';
import CampaignCreatePage from 'views/campaigns/features/campaign-add';
// import RowSettings from 'views/true-verifier/features/RowSettings';
import { setGlobalOpt } from 'store/custom';
import { FiSearch } from 'react-icons/fi';
import RowSettingsTrend from '../feature/RowSettingsTrend';

interface ColumnOption {
  name: string;
  id: string;
  [key: string]: any; // Allows any other properties with string keys
}

const TrafficTrendList = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [queryOffset, setQueryOffset] = React.useState(0);

  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    sort: { columnName: '', order: '' },
    search: '',
    filter: '',
  });
  // const [colViewOptions, setColViewOptions] = React.useState(false)
  const [columnOptions] = React.useState<ColumnOption[]>([
    {
      name: 'Name',
      id: 'site_validation_name',
      site_validation_name: true,
      disabled: true,
    },
    { name: 'Created On', id: 'cdate', cdate: true, disabled: true },
    { name: 'Status', id: 'client_url', client_url: true, disabled: true },
    { name: 'prospects_count', id: 'prospects_count', prospects_count: true },
  ]);

  const [columns, setColumns] = React.useState([]);
  const tableRef = React.useRef(null);
  const parentRef = React.useRef(null);
  const childRef = React.useRef(null);
  const campaignSearchRef = React.useRef(null);
  const debouncedSearchValue = useDebounce(queryParams?.search, 300);

  const dispatch = useAppDispatch();
  // const flagStatus = useAppSelector((state: { custom: { flagStatus: boolean; }; }) => state.custom.flagStatus);
  const columnHelper = createColumnHelper<SeoTrafficListRowObject>();
  // const [optionData, setOptionData] = React.useState<OptionDataType[]>([])
  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });
  const [loading, setLoading] = React.useState(false);

  const handleSort = (column: string) => {
    // console.log("column", column);
    setQueryParams((prev) => ({
      ...prev,
      sort: {
        columnName: column,
        order:
          prev.sort.columnName === column
            ? prev.sort.order === 'asc'
              ? 'desc'
              : 'asc'
            : 'asc',
      },
    }));
  };

  const handleCampaignSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
    // setQueryOffset(0);
  };
  React.useEffect(() => {
    const fetchSeoList = async () => {
      try {
        setLoading(true);
        const query = setAndGetQueryParams([
          { key: 'limit', value: 25 },
          { key: 'search', value: queryParams?.search },
          {
            key: 'sort',
            value: queryParams?.sort?.columnName
              ? `${
                  queryParams?.sort?.columnName !== 'cdate'
                    ? queryParams?.sort?.columnName
                    : '_id'
                }%${queryParams?.sort?.order}`
              : '',
          },
          { key: 'filter', value: queryParams?.filter },
        ]);

        const response = await BaseApi.get(`/seocountrytraffictrend?${query}`);
        console.log('TT RES', response?.data?.data);

        const data = response?.data?.data.length ? response?.data?.data : [];
        const count = response?.data?.data.length ? response?.data?.counts : 0;
        // if (response?.data?.data[0].length) {
        // const data = response?.data?.data.length
        //   ? response?.data?.data[0]?.data
        //   : [];
        // const count = response?.data?.data.length
        //   ? response?.data?.data[0]?.total_records.count
        //   : 0;
        // setData((prev) => ({
        //   ...prev,
        //   count: response?.data?.data[0]?.total_records.count || 0,
        //   data: response?.data?.data[0]?.data || [],
        // }));
        setData({
          data,
          count,
        });
        // console.log('response?.data?.data[0].data', response);
        setLoading(false);
        setQueryOffset(1);
        // } else {
        //   setData((prev) => ({
        //     ...prev,
        //     count: 0,
        //     data: [],
        //   }));
        // }
        // setData((prev) => ({
        //     ...prev,
        //     count: res_data?.length,
        //     data: res_data
        // }))
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchSeoList();
    // console.log(">>>", queryParams?.sort);
  }, [debouncedSearchValue, queryParams?.sort?.order]);

  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-5 flex items-center">
          <input
            type="checkbox"
            id="select-all"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="size-4 cursor-pointer accent-brand-500 dark:accent-purple-600"
          />
        </div>
      ),
      cell: (info) => {
        return (
          <div className="ml-5 flex items-center">
            <CheckBox info={info} table={table} />
          </div>
        );
      },
    }),
    columnHelper.accessor('client_url_name', {
      id: 'client_url_name',
      enableSorting: false,
      header: (row) => (
        <div className="select-none truncate font-semibold">Name</div>
      ),
      cell: (info: any) => (
        <>
          {
            <p
              className={`w-[300px] truncate pr-5 text-sm font-semibold row-campaign-${info?.row?.id}`}
              id={`campaign - ${info.getValue()}`}
            >
              {info?.row?.original?.list_name}
            </p>
          }
        </>
      ),
    }),
    columnHelper.accessor('cdate', {
      id: 'cdate',
      header: (row) => {
        return (
          <div
            className="column-cdate group relative w-full select-none text-nowrap font-semibold"
            onClick={() => handleSort('cdate')}
          >
            Created On
            {!row.column.getIsSorted() && (
              <IoArrowUp className="absolute right-12 top-0.5 hidden group-hover:block" />
            )}
            {row.column.getIsSorted() === 'asc' && (
              <IoArrowDown className="absolute right-12 top-0.5" />
            )}
            {(row.column.getIsSorted() === 'desc' ||
              !table.getState().sorting.length) && (
              <IoArrowUp className="absolute right-12 top-0.5" />
            )}
          </div>
        );
      },
      cell: (row) => {
        // const utcDateString = row.getValue();
        // // const userTimeZone = 'Asia/Kolkata';
        // // const utcDate = new Date(utcDateString);
        // // const localDate = utcDate.toLocaleString('en-US', { timeZone: userTimeZone });
        // const date = moment(new Date(utcDateString))

        return (
          <div className="group relative  w-full text-sm font-semibold">
            <span className={`w-28 row-cdate-${row?.row?.id}`}>
              {calculateTimeAgo(row.getValue())}
            </span>
            <ArrowedTooltip
              className={`left-[7.5rem] hidden group-hover:block    `}
              toolTipId={`row-cdate-tooltip-${row?.row?.id}`}
              data={moment(new Date(row.getValue())).format(
                'DD MMM YYYY, hh:mm A'
              )}
            />
          </div>
        );
      },
    }),

    columnHelper.accessor('url_list', {
      id: 'url_list',
      enableSorting: false,
      header: (row) => (
        <div className="column-campaign_progress_step_percent relative select-none font-semibold">
          Prospects Count
        </div>
      ),
      cell: (info: any) => {
        // const row = info?.row?.original
        return (
          <>
            {
              <div className="flex-col">
                <h5
                  className={`text-xs font-bold 
                                 dark:text-white`}
                >
                  {info?.row?.original?.urlListCount || 0}
                </h5>
                {/* <div className="w-5/12 bg-gray-200 mt-0.5 rounded-full h-[4px] dark:bg-gray-700">
                                <div className={`${info.row.original.campaign_status === 3 ? 'bg-[#8F9BBA]' : 'bg-green-600'} h-[4px] rounded-full w-[${row?.campaign_progress_step_percent}%]
                                    `}></div>
                            </div> */}
              </div>
            }
          </>
        );
      },
    }),

    columnHelper.accessor('uuid', {
      id: 'uuid',
      header: () => (
        <div className="relative mr-4 w-min font-semibold">Actions</div>
      ),
      cell: (info) => {
        return (
          <>
            <RowSettingsTrend info={info?.row?.original?.uuid} />
          </>
        );
      },
    }),
  ];

  React.useEffect(() => {
    const tempColumns = columnData.filter((val) => {
      const tempId = columnOptions.findIndex((option) => option.id === val.id);
      if (tempId === -1) return true;
      return columnOptions[tempId]?.[val.id] === true;
    });
    setColumns(tempColumns);
  }, [columnOptions]);

  React.useEffect(() => {
    const tableSelectedData = table
      .getSelectedRowModel()
      .rows.map((val: any) => val.original);
    dispatch(setCampaignSelectedCSVData(tableSelectedData));

    if (table.getSelectedRowModel().rows.length > 1)
      dispatch(setGlobalOpt(true));
    else dispatch(setGlobalOpt(false));
  }, [table.getSelectedRowModel()]);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        parentRef.current &&
        !parentRef.current.contains(event.target) &&
        !childRef?.current?.contains(event.target)
      ) {
        // setColViewOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [parentRef]);

  function calculateTimeAgo(timerange: string) {
    const timestamp = moment(timerange);
    const now = moment();

    // Calculate differences for each time unit
    const years = now.diff(timestamp, 'years');
    timestamp.add(years, 'years'); // Adjust timestamp after calculating years

    const months = now.diff(timestamp, 'months');
    timestamp.add(months, 'months'); // Adjust timestamp after calculating months

    const weeks = now.diff(timestamp, 'weeks');
    timestamp.add(weeks, 'weeks'); // Adjust timestamp after calculating weeks

    const seconds = now.diff(timestamp, 'seconds');
    const days = now.diff(timestamp.startOf('day'), 'days');
    // Determine and format the output
    if (seconds < 60) return 'just now';
    if (years > 0) return years === 1 ? 'year ago' : `${years} years ago`;
    if (months > 0) return months === 1 ? 'month ago' : `${months} months ago`;
    if (weeks > 0) return weeks === 1 ? 'week ago' : `${weeks} weeks ago`;
    if (days > 0) return days === 1 ? 'yesterday' : `${days} days ago`;
    return 'today';
  }

  const campaignFetch = React.useCallback(
    async (setIsFetching: any) => {
      const fetchCampaignData = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryOffset },
            { key: 'search', value: queryParams?.search },
            // {
            //   key: 'sort',
            //   value: queryParams?.sort?.columnName
            //     ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}`
            //     : '',
            // },
            {
              key: 'sort',
              value: queryParams?.sort?.columnName
                ? `${
                    queryParams?.sort?.columnName !== 'cdate'
                      ? queryParams?.sort?.columnName
                      : '_id'
                  }%${queryParams?.sort?.order}`
                : '',
            },
            { key: 'filter', value: queryParams?.filter },
          ]);
          // console.log('queryParams', queryParams);

          const response = await BaseApi.get(
            `/seocountrytraffictrend?${query}`
          );
          //   console.log(
          //     queryOffset,
          //     'scrollResponse',
          //     response?.data?.data[0].data
          //   );

          const responseData: any = response?.data;
          //   if (!response?.data?.data[0].data)
          //     return setData({ data: [], count: 0 });
          //   if (response?.data?.data[0].data?.length < 25) {
          //     setData((prev) => ({
          //       data: [...prev?.data, ...responseData],
          //       count: prev?.data?.length,
          //     }));
          //   } else {
          //     setData((prev) => ({
          //       ...prev,
          //       data: [...prev?.data, ...responseData],
          //     }));
          //   }
          if (!responseData?.data) return setData({ data: [], count: 0 });
          if (responseData?.data?.length < 25) {
            setData((prev) => ({
              data: [...prev?.data, ...responseData?.data],
              count: prev?.data?.length,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryOffset(queryOffset + 1);
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
          // console.log('data', data)
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length &&
        data?.data?.length < data?.count &&
        fetchCampaignData();
    },
    [
      data?.data?.length,
      queryParams?.filter,
      queryParams?.sort?.columnName,
      queryParams?.sort?.order,
      queryParams?.offset,
    ]
  );
  //   console.log(data?.data.length);
  return (
    <>
      <div
        className={`mt-2.5 h-full w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-navy-700 dark:text-white sm:overflow-x-auto`}
      >
        <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
          <div className="flex gap-2.5"></div>
          <div
            className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 md:w-[13rem] lg:w-[26rem]"
            onClick={() => campaignSearchRef.current.focus()}
          >
            <FiSearch className="size-4 cursor-pointer" />
            <input
              ref={campaignSearchRef}
              type="text"
              placeholder="Search"
              id="campaign-search-input"
              className="w-full text-sm font-bold text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-navy-700 dark:placeholder:text-white "
              onChange={handleCampaignSearch}
            />
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => navigate('/seo/traffictrend/create')}
              id="new-traffic-trend-create-btn"
              className="mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              <MdAdd className="float-right text-lg" />{' '}
              <span className="text-nowrap text-[11px]">New Traffic Trend</span>
            </button>
          </div>
        </div>
        {loading ? (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={10}
            row={13}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
          />
        ) : (
          <div
            className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden"
            ref={tableRef}
          >
            <table className=" sm:w-[150%] lg:w-[120%] xl:w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={` ${index === 0 && 'w-[55px]'} ${
                            index === arr.length - 1 && 'w-[20px]'
                          } ${
                            index === 1 && 'w-[300px]'
                          } mb-2 cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-center dark:border-white/10 dark:bg-navy-900`}
                        >
                          <div className="text-xs">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px]">
                {data?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className="hover:bg-hover dark:hover:bg-navy-900"
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className={`border-white/0 py-3 text-center ${
                                cell.row.original.campaign_status === 3
                                  ? 'text-[#8F9BBA]'
                                  : ' text-[#2B3674]'
                              } dark:text-white`}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              loadMore={campaignFetch}
              loader={
                <TableLoader
                  width="w-full"
                  border="border border-gray-100"
                  column={10}
                  row={1}
                  skeletonHeight="h-2"
                  padding="px-5 py-4"
                  columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
                />
              }
            />
          </div>
        )}
      </div>
      <CampaignCreatePage />
    </>
  );
};

export default TrafficTrendList;
