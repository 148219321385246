import React, { useState } from 'react';
import { IoMdMore } from 'react-icons/io';
import { MdOutlineCloudDownload } from 'react-icons/md';
// import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { BaseApi } from 'common/api/common/base-api';

// import { BaseApi } from 'common/api/common/base-api';
interface RowData {
  id?: string;
  uuid?: any;
  info?: any;
}
// interface csvTempData {
//     campaign_name: any,
//     cdate: any,
//     created_by: any,
//     campaign_status: any,
//     campaign_progress_step_percent: any,
//     campaign_delivery_message_data: any
// }

const RowSettingsTrend = (props: RowData) => {
  const { info } = props;
  // const campaign = useAppSelector(state => state?.campaign)
  // const custom = useAppSelector(state => state?.custom)
  const [showOptions, setShowOptions] = useState(false);
  // const [data, setData] = useState([])
  // console.log('info', info)

  const downloadCsv = (res: any) => {
    // console.log('result', res);
    // const csvContent = convertArrayOfObjectsToCSV(res);
    // // console.log(csvContent);
    // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    // saveAs(blob, 'data.csv');

    // const wb = XLSX.utils.book_new();
    // // const headers = ["client_Url","Prospects Url", '2023-06-01', '2023-07-01', '2023-08-01', '2023-09-01', '2023-10-01', '2023-11-01', '2023-12-01', '2024-01-01', '2024-02-01', '2024-03-01', '2024-04-01', '2024-05-01']
    // const headers = Object.keys(res);
    // const ws = XLSX.utils.json_to_sheet(res, { header: headers });
    // XLSX.utils.book_append_sheet(wb, ws, 'SEO');
    // const wbout = XLSX.write(wb, { bookType: 'csv', type: 'binary' });
    // const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'Seo-traffic-trend-data';
    // document.body.appendChild(a);
    // a.click();
    // window.URL.revokeObjectURL(url);
    // a.remove();

    const wb = XLSX.utils.book_new();

    // Assuming `res` is an array of objects and all objects have the same keys.
    // Using the first object to define headers. Ensure that the array is not empty.
    const headers = res.length > 0 ? Object.keys(res[0]) : [];
    const ws = XLSX.utils.json_to_sheet(res, { header: headers });

    XLSX.utils.book_append_sheet(wb, ws, 'SEO');
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'binary' });

    // Creating a blob for downloading
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);

    // Creating a link for download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Seo-traffic-trend-data.csv'; // Making sure the filename has a .csv extension
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  // const convertArrayOfObjectsToCSV = (array: any): string => {
  //   if (array.length === 0) {
  //     return '';
  //   }
  //   const headers = Object.keys(array[0]).join(',');
  //   const rows = array.map((obj: any) => Object.values(obj).join(','));
  //   return [headers, ...rows].join('\n');
  // };

   const fetchExcelData = async (listId: string) => {
      // console.log('listed ID>>', listId);
      if (listId) {
        let response = await BaseApi.get(`/seocountrytraffictrend/${listId}`);
        //   console.log('response', response);
        const responseData = response?.data;
        downloadCsv(responseData);
  
        //   setExcel_data(responseData);
      }
    };

  const handleOptions = (selectedOpt: string) => {
    if (selectedOpt === 'download') {
      fetchExcelData(info)
      setShowOptions(false);

      // let res = info?.original?.url_list?.flatMap((item: any) => {
      //     const prospect_url = item?.url;

      //     return item?.result?.map((resultItem: any) => ({
      //         client_url_name,
      //         prospect_url,
      //         country: resultItem.country,
      //         org_traffic: resultItem.org_traffic,
      //         org_keywords: resultItem.org_keywords,
      //         org_keywords_1_3: resultItem.org_keywords_1_3
      //     }));
      // }).filter(Boolean) || [];
      // for (let item of info?.original?.url_list) {
      //     const itemList = item?.result;
      //     for (let resultItemIndex in itemList) {
      //         res_data.push({ ...itemList[resultItemIndex], client_url_name, prospect_url: item?.url, rank: +resultItemIndex + 1 })
      //     }
      // }
      //   console.log(info?.original?.url_list, 'RES CSV');
    }
  };

  return (
    <>
      <div
        id="seo-traffic-trend-list-download-btn"
        className={`relative mr-3 w-min cursor-pointer rounded-md py-0.5 ${
          showOptions && 'bg-[#4318FF0A]'
        }`}
        tabIndex={0}
        onBlur={() => setShowOptions(false)}
      >
        <IoMdMore
          className="z-0 text-lg text-gray-700"
          onClick={() => setShowOptions(true)}
        />
        {showOptions && (
          <div className="absolute -left-[75px] z-10 flex flex-col overflow-hidden rounded-md border bg-white">
            <div
              id="seo-tt-download-btn"
              className="campaign-download-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
              onClick={() => handleOptions('download')}
            >
              <MdOutlineCloudDownload className="text-brand-500 group-hover:text-white" />
              <h5 className="text-xs">Download</h5>
            </div>
          </div>
        )}
      </div>
      {/* {custom?.globalOpt && <GlobalOpt handleOptions={handleOptions} />} */}
    </>
  );
};
export default RowSettingsTrend;
